import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useJobsList() {
  // Use toast
  const toast = useToast();

  const refJobListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'user', sortable: true },
    { key: 'cohort', sortable: true },
    { key: 'metadata', sortable: true },
    { key: 'entity', sortable: true },
    { key: 'updatedAt', sortable: true },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalJobs = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('updatedAt');
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refJobListTable.value ? refJobListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalJobs.value,
    };
  });

  const refetchData = () => {
    refJobListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData();
  });

  const fetchJobs = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    store
      .dispatch('jobStoreModule/fetchJobs', {
        // q: searchQuery.value,
        limit: perPage.value,
        // sortDesc: isSortDirDesc.value,
        page: currentPage.value - 1,
        sortBy: sb,
        populate: 'user,cohort',

        role: roleFilter.value,
      })
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalJobs.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching jobs list-pending',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveJobRoleVariant = (role) => {
    if (role === 'subscriber') return 'info';
    if (role === 'author') return 'warning';
    if (role === 'maintainer') return 'success';
    if (role === 'editor') return 'info';
    if (role === 'admin') return 'danger';
    return 'info';
  };

  const resolveJobRoleIcon = (role) => {
    if (role === 'subscriber') return 'JobIcon';
    if (role === 'author') return 'SettingsIcon';
    if (role === 'maintainer') return 'DatabaseIcon';
    if (role === 'editor') return 'Edit2Icon';
    if (role === 'admin') return 'ServerIcon';
    return 'JobIcon';
  };

  const resolveJobStatusVariant = (status) => {
    if (status === 'error') return 'info';
    if (status === 'stalled') return 'warning';
    if (status === 'completed') return 'success';
    if (status === 'processing') return 'info';
    return 'info';
  };

  return {
    fetchJobs,
    tableColumns,
    perPage,
    currentPage,
    totalJobs,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refJobListTable,

    resolveJobRoleVariant,
    resolveJobRoleIcon,
    resolveJobStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
