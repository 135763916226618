<template>
  <div>
    <!-- Filters -->
    <jobs-list-filters v-if="false" :status-filter.sync="statusFilter" :status-options="statusOptions" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6"> </b-col>
        </b-row>
      </div>

      <b-table
        ref="refJobListTable"
        class="position-relative"
        :items="fetchJobs"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(id)="data">
          <a v-if="data.item.metadata.secure" :href="data.item.metadata.secure" target="_blank">
            {{ data.item.id }}
          </a>
          <a v-else-if="data.item.metadata.location" :href="data.item.metadata.location" target="_blank">
            {{ data.item.id }}
          </a>
          <span v-else>{{ data.item.id }}</span>
        </template>

        <template #cell(cohort)="data">
          <a v-if="data.item.cohort" :href="`/accounting/receivable/cohort/${data.item.cohort.id}`" target="_blank">
            {{ data.item.cohort.name }}
          </a>
          <span v-else>No Cohort</span>
        </template>

        <!-- Column: Job -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.value.name)"
                :variant="`light-${resolveJobRoleVariant(data.value.role)}`"
                :to="{ name: 'apps-jobs-view', params: { id: data.value.id } }"
              />
            </template>
            <b-link :to="{ name: 'apps-users-view', params: { id: data.value.id } }" class="font-weight-bold d-block text-nowrap">
              {{ data.value.name }}
            </b-link>
            <small class="text-muted">@{{ data.value.username }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(metadata)="data">
          <span v-if="data.item.metadata.message">
            {{ data.item.metadata.message }}
          </span>
          <span v-else-if="data.item.metadata.ids"> {{ data.item.metadata.ids.length }} total items </span>
          <span v-else>No more information to provide.</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolveJobStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Updated At -->
        <template #cell(updatedAt)="data">
          <span class="text-nowrap">
            {{ moment(data.value).format('L HH:mm:ss') }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="reRunJob(data.item.id)">
              <feather-icon icon="PlayIcon" />
              <span class="align-middle ml-50">Re-Run</span>
            </b-dropdown-item>
            <b-dropdown-item @click="downloadJob(data.item)">
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalJobs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import JobsListFilters from './JobsListFilters.vue';
import useJobsList from './useJobsList';
import jobStoreModule from '../jobStoreModule';
import moment from 'moment';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    JobsListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data: () => {
    return {
      timer: undefined,
    };
  },
  mounted() {
    this.timer = setInterval(this.refetchData, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    downloadJob(job) {
      if (job.metadata.secure) {
        window.open(job.metadata.secure, '_blank');
      } else if (job.metadata.location) {
        window.open(job.metadata.location, '_blank');
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to download file.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Job has no attached download URL',
          },
        });
      }
    },
    reRunJob(jobId) {
      this.$http
        .patch(`v1/jobs/${jobId}`, { status: 'created' })
        .then(this.refetchData)
        .catch((error) => {
          this.processing = false;

          if (error.response) {
            const { data } = error.response;

            if (data.message) {
              return this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to update job',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: data.message,
                },
              });
            }
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update job',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'An unknown error has occurred.',
            },
          });
        });
    },
  },
  setup() {
    const statusOptions = [
      { label: 'Created', value: 'created' },
      { label: 'Processing', value: 'processing' },
      { label: 'Completed', value: 'completed' },
      { label: 'Error', value: 'error' },
    ];

    const {
      fetchJobs,
      tableColumns,
      perPage,
      currentPage,
      totalJobs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refJobListTable,
      refetchData,

      // UI
      resolveJobRoleVariant,
      resolveJobRoleIcon,
      resolveJobStatusVariant,

      // Extra Filters
      statusFilter,
    } = useJobsList();

    return {
      // Sidebar

      fetchJobs,
      tableColumns,
      perPage,
      currentPage,
      totalJobs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refJobListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveJobRoleVariant,
      resolveJobRoleIcon,
      resolveJobStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,

      moment,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
